import store from "../store";

export default [
  {
    path: "/login",
    name: "LoginComponent",
    component: () => import("../components/access/LoginComponent.vue"),
    meta: { requiresAuth: false },
  },

  {
    path: "/recuperar_contrasena",
    name: "RecuperarContrasenaComponent",
    component: () =>
      import("../components/access/RecuperarContrasenaComponent.vue"),
    meta: { requiresAuth: false },
  },

  {
    path: "/cambiar_contrasena",
    name: "CambiarContrasenaComponent",
    component: () =>
      import("../components/access/CambiarContrasenaComponent.vue"),
    meta: { requiresAuth: false },
  },

  {
    path: "/",
    name: "IndexComponent",
    component: () => import("../components/dashboard/IndexComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/inicio"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/reservar_guias",
    name: "ReservasFincaComponent",
    component: () =>
      import("../components/coordinacion/ReservasFincaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/ingresoReservasFincas"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/mis_reservas_coord",
    name: "ReservasCoordinadasComponent",
    component: () =>
      import("../components/coordinacion/ReservasCoordinadasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/reservasCoordFincas"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/historial_embarques",
    name: "HistorialEmbarquesFincaComponent",
    component: () =>
      import("../components/coordinacion/HistorialEmbarquesFincaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/historialEmbarques"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/agente_carga",
    name: "AgenteCarga",
    component: () => import("../components/administracion/AgenteComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/agentesCarga"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/shipper",
    name: "Shipper",
    component: () =>
      import("../components/administracion/ShipperComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaShippers"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/lista_daes",
    name: "listaDaes",
    component: () =>
      import("../components/coordinacion/AdministrarDaesFincaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaDaes"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/lista_mawb",
    name: "listaMawb",
    component: () =>
      import("../components/coordinacion/ListaMawbCoordComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaMawbCoord"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/lista_daes_gen",
    name: "listaDaesGen",
    component: () =>
      import("../components/coordinacion/AdministrarDaesComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaDaesGeneral"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/fincas",
    name: "Fincas",
    component: () =>
      import("../components/administracion/ListaFincasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaFincas"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/usuarios",
    name: "Usuarios",
    component: () =>
      import("../components/administracion/ListaUsuariosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaUsuarios"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/usuarios_fincas",
    name: "UsuariosFincas",
    component: () =>
      import("../components/administracion/UsuariosFincasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/accesosFincas"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/lista_guias",
    name: "ListaGuiasAereas",
    component: () =>
      import("../components/coordinacion/GuiasAereasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      next();
      if (store.getters["access/listaGuiasAereas"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/historial_reservas",
    name: "HistorialReservasFincaComponent",
    component: () =>
      import("../components/coordinacion/HistorialReservasFincaComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/historialEmbarques"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/reservas_consolidado",
    name: "ReservaFincaConsolidado",
    component: () =>
      import("../components/coordinacion/ReservaFincaConsolidado.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/reservasConsolidado"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/confirmar_reservas",
    name: "ConfirmarReservas",
    component: () =>
      import("../components/coordinacion/ConfirmarReservasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/confirmarReservas"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
  {
    path: "/coordinar_guias",
    name: "CoordinacionWebGuias",
    component: () =>
      import("../components/coordinacion/CoordinacionWebGuiasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/ingresoReservasAdmin"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
  {
    path: "/permisos",
    name: "Permisos",
    component: () =>
      import("../components/administracion/PermisosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/permisos"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
  {
    path: "/privilegios",
    name: "Privilegios",
    component: () =>
      import("../components/administracion/PrivilegiosComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/privilegios"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
  {
    path: "/inventario_guias",
    name: "InventarioGuias",
    component: () =>
      import("../components/coordinacion/InventarioGuiasComponent.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/inventarioGuias"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
  {
    path: "/guia_aerea",
    name: "GuiaAereaForm",
    component: () => import("../components/coordinacion/GuiaAereaForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/guiaMasterForm"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/reservar_guia_aerea/:hcoordId/:awb/:aerolineaId",
    name: "ReservarGuiaAerea",
    component: () => import("../components/coordinacion/GuiaAereaForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/privilegios"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/editar_guia_aerea/:awb",
    name: "EditarGuiaAerea",
    component: () => import("../components/coordinacion/GuiaAereaForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/guiaMasterForm"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/coordinacion_guia_aerea/:awb",
    name: "CoordinacionGuiaAerea",
    component: () => import("../components/coordinacion/CoordinacionGuia.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/coordiacionGuiaAerea"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/cambio_guia_coord",
    name: "CambioGuiaCoord",
    component: () =>
      import("../components/coordinacion/CambioGuiaCoordinacion.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/cambiarGuiaCoordinacion"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/cambio_hawb_awb",
    name: "CambioHawbAwb",
    component: () => import("../components/coordinacion/CambioHawbAwb.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/cambiarCargaGuia"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/hoja_coordinacion",
    name: "HojaCoordinacion",
    component: () => import("../components/coordinacion/ListaHcoord.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/hojaCoordinacion"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/aerolineas",
    name: "Aerolineas",
    component: () => import("../components/administracion/Aerolineas.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaAerolineas"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/notify",
    name: "Notify",
    component: () => import("../components/administracion/Notifies.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaNotifies"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/aeropuertos",
    name: "Aeropuerto",
    component: () => import("../components/administracion/Aeropuertos.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaAeropuertos"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/paises",
    name: "Paises",
    component: () => import("../components/administracion/Paises.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaPaises"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/clientes/:clienteId?",
    name: "Clientes",
    component: () => import("../components/administracion/Clientes.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaClientes"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/transportistas",
    name: "Transportistas",
    component: () => import("../components/administracion/Transportistas.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaTransportistas"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/carga_bodega",
    name: "TransaccionesBodega",
    component: () => import("../components/bodega/TransaccionesBodega.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/cargaBodega"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/asignar_transp",
    name: "AsignarTransportistas",
    component: () => import("../components/bodega/AsignarTransportistas.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/asignarTransportistas"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/ingreso_dimensiones",
    name: "IngresoDimensiones",
    component: () => import("../components/bodega/IngresoDimensiones.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/dimensionesCajas"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
  {
    path: "/contactos",
    name: "Contactos",
    component: () => import("../components/administracion/Contactos.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaContactos"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/form_contacto/:contactoId",
    name: "FormContacto",
    component: () => import("../components/administracion/ContactoForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/formContacto"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/pod_lista",
    name: "ListaPod",
    component: () => import("../components/bodega/PodLista.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/listaPod"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/form_pod/:podId",
    name: "FormPod",
    component: () => import("../components/bodega/PodForm.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/formPod"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/participantes/",
    name: "Participantes",
    component: () => import("../components/administracion/Participante.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/participantes"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/resumen_transportista/",
    name: "ResumenTransportista",
    component: () => import("../components/bodega/ResumenTransportista.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/resumenTransp"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/guias_hijas_mia",
    name: "GuiasHijasMiami",
    component: () => import("../components/guias/Hawb.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/guiasHijasMiami"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/guias_madres_mia",
    name: "GuiasMadresMiami",
    component: () => import("../components/guias/Mawb.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/guiasMadresMiami"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/recibir_cajas",
    name: "RecibirCajas",
    component: () => import("../components/recepcion/Recibircajas.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/privilegios"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/invoices",
    name: "Invoices",
    component: () => import("../components/facturacion/Invoice.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/facturacion"]) {
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },

  {
    path: "/reservas2",
    name: "ReservaFinca2",
    component: () => import("../components/reservas/ReservaFinca.vue"),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters["access/reservasConsolidado"]) {
        //store.commit('master/setMenu',store.state.general.menu)
        next();
      } else {
        store.dispatch("master/alertNotification", {
          param: store.state.access.deniedUser,
        });
      }
    },
  },
];
